<template>
  <div class="uk-container uk-container-expand uk-margin-top card-scrollable">
    <div
      class="uk-width-1-1 uk-flex uk-flex-column-sm uk-flex-column-md uk-flex-row uk-flex-between uk-flex-middle"
    >
      <div class="uk-width-1-1 uk-width-auto@l">
        <h1 class="uk-heading-line">
          {{ headerTitle }}
        </h1>
      </div>

      <div class="uk-width-1-1 uk-width-auto@l uk-margin-small-top uk-margin-remove-top-lg uk-margin-remove-top-xl">
        <button
          v-if="isCanAccessUser(`add`, `Purchase Order`)"
          class="uk-button green uk-width-1-1"
          @click.prevent="$router.push({ name: 'PurchaseOrderFarmerCreate' })"
        >
          <img
            :src="`${images}/icon/ic_plus_white.svg`"
            class="uk-margin-small-right"
            alt="ic_plus"
            loading="lazy"
          >
          Create PO
        </button>
      </div>
    </div>

    <div class="uk-card uk-card-default uk-margin-top base-card">
      <filter-po-farm
        :images="images"
        :list-species="listSpecies"
        :list-varietas="listVarietas"
        :filter-submit="filterSubmit"
        :reset-filter="resetFilter"
        :meta-filter="metaFilter"
      />
      <container
        :images="images"
        :list="listPo.data"
      />
      <pagination
        :total-data="totalData"
        :change-limit="changeLimit"
        :change-page="changePage"
        :current-page="currentPage"
        :current-limit="currentLimit"
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapGetters } from 'vuex'
import FilterPoFarm from './Filter'
import Container from './Container'
import Pagination from '@/components/globals/Pagination'
import getHeaderTitle from '@/utils/header-title'
import { PREFIX_IMAGE } from '@/utils/constant'
import { isCanAccess } from '@/utils/auth'

export default {
  components: {
    FilterPoFarm,
    Container,
    Pagination
  },
  props: {
    dataRole: {
      required: true,
      type: Array
    }
  },
  data() {
    return {
      images: PREFIX_IMAGE,
      totalData: 10,
      currentPage: 1,
      currentLimit: 10
    }
  },
  computed: {
    ...mapGetters({
      listSpecies: 'masterData/list_species_type',
      listVarietas: 'masterData/list_varieties_type',
      listPo: 'purchaseOrder/list_po',
      metaFilter: 'purchaseOrder/meta'
    }),
    headerTitle() {
      return getHeaderTitle(this.$route.fullPath)
    }
  },
  watch: {
    listPo(data) {
      this.totalData = data.meta.total
    }
  },
  async mounted() {
    this.loadPage()
  },
  methods: {
    ...mapActions({
      actionGetListVarietas: 'masterData/getListVarietiesType',
      actionGetListSpecies: 'masterData/getListSpeciesType',
      actionGetListPo: 'purchaseOrder/getListPo'
    }),
    ...mapMutations({
      setMetaFilter: "purchaseOrder/meta"
    }),
    isCanAccessUser(type, menu) {
      return isCanAccess(type, menu)
    },
    async loadPage() {
      await this.actionGetListVarietas({data_type: 'varieties', limit: 1000})
      await this.actionGetListSpecies({data_type: 'species', limit: 1000})
      await this.actionGetListPo({ page: this.currentPage, limit: this.currentLimit})
    },
    async filterSubmit() {
      await this.actionGetListPo({...this.metaFilter, page: 1, limit: 10})
    },
    async resetFilter() {
      this.currentPage = 1
      this.currentLimit = 10
      await this.actionGetListPo({ page: this.currentPage, limit: this.currentLimit})
    },
    async changePage(page) {
      this.currentPage = parseInt(page)
      await this.actionGetListPo({...this.metaFilter, page, limit: this.currentLimit})
    },
    async changeLimit(event) {
      this.currentLimit = event.target.value
      this.currentPage = 1
      await this.actionGetListPo({...this.metaFilter, limit: event.target.value, page: 1})
    }
  }
}
</script>
