<template>
  <table-content
    :images="images"
    :list="list"
    :list-header="listHeader"
  />
</template>
  
<script>
import TableContent from './table/index.vue'
  
export default {
  components: {
    TableContent
  },
  props: {
    images: {
      required: false,
      default: '',
      type: String
    },
    list: {
      required: false,
      default: Object,
      type: Object
    }
  },
  data() {
    return {
      listHeader: [ 
        {
          key: 'purchase_order_number',
          label: 'PO Number',
          custom: 'sort'
        },
        {
          key: 'facility_name',
          label: 'Nama Petani',
          custom: 'sort'
        },
        {
          key: 'weight',
          label: 'Total Berat',
          custom: 'sort'
        },
        {
          key: 'purchase_order_date',
          label: 'Tanggal PO',
          custom: 'sort'
        },
        {
          key: 'purchase_order_received_date',
          label: 'Tanggal Terima PO',
          custom: 'sort'
        },
        {
          key: 'status',
          label: 'Status',
          custom: 'sort'
        },
        {
          key: 'action',
          label: 'Action'
        }
      ]
    }
  }
}
</script>
  
