<template>
  <div :id="id">
    <vue-html2pdf
      ref="html2Pdf"
      :show-layout="false"
      :float-layout="true"
      :enable-download="false"
      :preview-modal="true"
      :paginate-elements-by-height="1400"
      filename="hee hee"
      :pdf-quality="2"
      :manual-pagination="false"
      pdf-format="a4"
      pdf-orientation="portrait"
      
      pdf-content-width="800px"
      @progress="onProgress($event)"
      @hasStartedGeneration="hasStartedGeneration()"
      @hasGenerated="hasGenerated($event)"
    >
      <section slot="pdf-content">
        <div class="container">
          <div class="header">
            <h1>PO Farmer Detail</h1>
          </div>
          <div class="details">
            <div class="row">
              <div class="column">
                <label>PO Number:</label>
                <input
                  type="text"
                  :value="purchase_order_code"
                >
              </div>
              <div class="column">
                <label>Farmer Name:</label>
                <input
                  type="text"
                  :value="farmer_name"
                >
              </div>
            </div>
            <div class="row">
              <div class="column">
                <label>PO Date:</label>
                <div class="input-group">
                  <input
                    type="text"
                    :value="dateNowParse(purchase_order_date)"
                  >
                  <img
                    src="calendar.svg"
                    alt="calendar icon"
                  >
                </div>
              </div>
              <div class="column">
                <label>Facility Code:</label>
                <input
                  type="text"
                  :value="facility_code"
                >
              </div>
            </div>
          </div>
          <div class="products">
            <h4>Product Type</h4>
            <table class="product-table">
              <thead>
                <tr>
                  <th>No</th>
                  <th>Varietas</th>
                  <th>Berat</th>
                  <th>Penerimaan</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>1</td>
                  <td>Timtim</td>
                  <td>12</td>
                  <td>12</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </section>
    </vue-html2pdf>
  </div>
</template>

<script>
import VueHtml2pdf from 'vue-html2pdf'
import { mapGetters, mapActions } from 'vuex'
import {dateNowParse} from '@/utils/formater'

export default {
  components: {
    VueHtml2pdf
  },
  props: {
    id: {
      required: true,
      type: String
    }
  },
  data(){
    return{
      purchase_order_code:'',
      farmer_name: '',
      facility_code:'',
      purchase_order_date: '',
      purchase_order_received_date: ''  
    }
  },
  computed: {
    ...mapGetters({
      dataPoFarmerDetail: 'purchaseOrder/dataPoFarmerDetail'
    })
  },
  watch:{
    async id(){
      await this.getPoFarmerDetail(this.id)
    },
    dataPoFarmerDetail(){
      this.purchase_order_code = this.dataPoFarmerDetail.purchase_order_code
      this.farmer_name = this.dataPoFarmerDetail.farmer.name
      this.facility_code = this.dataPoFarmerDetail.facility_code
      this.purchase_order_date = this.dataPoFarmerDetail.purchase_order_code
      this.purchase_order_received_date = this.dataPoFarmerDetail.purchase_order_received_date
      this.generateReport()
    }
  },
  methods: {
    ...mapActions({
      getPoFarmerDetail: 'purchaseOrder/getPoFarmerDetail'
    }),
    generateReport () {
      this.$refs.html2Pdf.generatePdf()
    },
    dateNowParse(t){
      return dateNowParse(t)
    }
  }
}
</script>

<style scoped>
.container {
    width: 800px; /* Adjust width as needed */
    margin: 0 auto; /* Center the container horizontally */
    padding: 20px;
  }
  
  .header {
    text-align: center;
  }
  
  .header h1 {
    font-size: 24px;
    margin-bottom: 20px;
  }
  
  .details,
  .products {
    margin-bottom: 20px;
  }
  
  .details .row {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 10px;
  }
  
  .details .column {
    flex: 1 1 50%;
    padding: 5px;
  }
  
  .details label {
    display: block;
    margin-bottom: 5px;
  }
  
  .details .input-group {
    display: flex;
    align-items: center;
  }
  
  .details input[type="text"] {
    margin-left: 20px;
    border: none;
  }
  
  .products table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .products th,
  .products td {
    padding: 10px;
    border: 1px solid #ddd;
    text-align: left;
  }
  
  .products th {
    font-weight: bold;
  }
</style>
