<template>
  <div class="uk-margin-top">
    <div class="uk-overflow-auto border-table">
      <table
        class="uk-table uk-table-divider uk-table-middle uk-table-hover"
        aria-describedby="po-farmer-table"
      >
        <table-header :table-header="listHeader" />
        <template v-if="list">
          <tbody>
            <tr
              v-for="(content, index) in list"
              :key="index"
            >
              <td>{{ content.purchase_order_code || '-' }}</td>
              <td>{{ content.farmer_name || '-' }}</td>
              <td>{{ content.total_weight + ' Kg' || '-' }}</td>
              <td>{{ dateNowParse(content.purchase_order_date) || '-' }}</td>
              <td>{{ dateNowParse(content.purchase_order_received_date)|| '-' }}</td>
              <td>
                <div v-if="content.status === true">
                  <label-status
                    status="active"
                    label="Open"
                    table="true"
                  />
                </div>
                <div v-else-if="content.status === false">
                  <label-status
                    status="inactive"
                    label="Closed"
                    table="true"
                  />
                </div>
              </td>
              <td class="action">
                <a
                  v-if="content.status === true"
                  uk-tooltip="Export"
                >
                  <img
                    :src="`${images}/icon/ic_printer_orange.svg`"
                    alt=""
                    loading="lazy"
                    @click.prevent="handleExport(content.id)"
                  >
                </a>
                <a
                  uk-tooltip="View"
                >
                  <img
                    :src="`${images}/icon/ic_eye_table.svg`"
                    alt=""
                    loading="lazy"
                    @click.prevent="$router.push({ name: 'PurchaseOrderFarmerDetail', params:{ id: content.id }})"
                  >
                </a>
              </td>
            </tr>
          </tbody>
        </template>
        <template v-else>
          <empty-table
            :colspan="7"
          />
        </template>
      </table>
    </div>
    <export-po-farmer :id="id" />
  </div>
</template>

<script>
import TableHeader from './header'
import EmptyTable from "@/components/globals/table/EmptyTable"
import LabelStatus from '@/components/globals/LabelStatus'
import ExportPoFarmer from '../ExportPoFarmer.vue'
import {dateNowParse} from '@/utils/formater'

export default {
  components: {
    TableHeader,
    EmptyTable,
    LabelStatus,
    ExportPoFarmer
  },
  props: {
    images: {
      required: true,
      type: String
    },
    list: {
      required: true,
      type: Object
    },
    listHeader: {
      required: true,
      type: Array
    }
  },
  data(){
    return{
      id:''
    }
  },
  methods: {
    dateNowParse(t){
      return dateNowParse(new Date(t)) 
    },
    handleExport(id){
      this.id=id
    }
  }
}
</script>

<style scoped>
.disabled {
  cursor: no-drop;
}
</style>
